import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const UserTable = ({ users }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Table striped bordered hover className="mt-2">
      <thead>
        <tr>
          <th>Username</th>
          <th>roles</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, key) => (
          <tr
            key={key}
            onClick={() => navigate(`/manage/${id}/users/${user.user}`)}
            style={{ cursor: "pointer" }}
          >
            <td>{user.user}</td>
            <td>{user.roles.toString()}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UserTable;
