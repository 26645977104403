import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../UtilFunctions";

const UserTable = ({ users }) => {
  const navigate = useNavigate();

  return (
    <Table striped bordered hover className="mt-2">
      <thead>
        <tr>
          <th>Username</th>
          <th>Status</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, key) => (
          <tr
            key={key}
            onClick={() => navigate(`/admin-panel/users/${user.username}`)}
            style={{ cursor: "pointer" }}
          >
            <td>{user.username}</td>
            <td>{user.userStatus}</td>
            <td>{formatDate(user.created_at)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UserTable;
