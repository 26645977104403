import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { AuthStoreProvider, authStore } from './stores/AuthStore';
import { create } from 'mobx-persist';

create({})('auth', authStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AuthStoreProvider store={authStore}>
		<CookiesProvider>
			<HelmetProvider>
				<Helmet>
					<title>Cloudway Accounts</title>
					<meta name="description" content="Cloudway Accounts Application" />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta charset="UTF-8" />
				</Helmet>
					<App />
			</HelmetProvider>
		</CookiesProvider>
	</AuthStoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
