import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getLowestAllocation } from "../../ApiConnection";
import {useAuthStore} from '../../stores/AuthStore';
import { Spinner } from 'react-bootstrap';

const LowestAllocationTable = ({ setAuthError }) => {
  const { token, tenant } = useAuthStore();
  const amount = 5;
  const [employees, setEmployees] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getData() {
      try {
        setEmployees(await getLowestAllocation(token, tenant, amount));
      } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403) setAuthError(e);
        else setError(e);
      }
    }
    getData();
  }, [amount]);

  return (
    <Card className="h-100">
      <Card.Header>
        <b>Lowest Allocation (today)</b>
      </Card.Header>
      <Card.Body>
        <div>
          {error ? (
            <p>
              <b>Error:</b> {error.response.data.message}
            </p>
          ) : !employees ? (
            <Spinner className="mt-2" animation="border" />
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Allocation</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((arrayData) => {
                  return (
                    <tr
                      key={arrayData.id}
                      onClick={() => navigate(`/employees/${arrayData.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>
                        {arrayData.name} {arrayData.lastname}
                      </td>
                      <td>{arrayData.allocation}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default LowestAllocationTable;