import React from "react";
import { getAllocation } from "../../ApiConnection";
import { Line } from "react-chartjs-2";
import { debounce } from 'lodash'
import { Chart, LineElement, Legend, Tooltip, TimeScale, LinearScale, PointElement, CategoryScale, Title } from 'chart.js';
import { DateTime } from 'luxon';
import { useAuthStore } from '../../stores/AuthStore';
import {Card, Form, Spinner} from "react-bootstrap";
import { colors } from '../../UtilFunctions'

 
Chart.register(LineElement, Legend, Tooltip, TimeScale, LinearScale, PointElement, CategoryScale, Title);

const AGGREGATION_TYPE = 'month';

const ProvisionalAllocationChart = ({ setAuthError }) => {
    const [chartData, setChartData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(DateTime.utc().startOf('month').minus({ month: 2 }).toJSDate());
    const [endDate, setEndDate] = React.useState(DateTime.utc().startOf('month').plus({ month: 3 }).toJSDate());
    const [error, setError] = React.useState('');
    const { token, tenant } = useAuthStore();

  const getData = async() => {
    try {
      const data = await getAllocation(token, tenant, {
        aggregationType: AGGREGATION_TYPE,
        startDate: DateTime.fromJSDate(startDate, { zone: 'utc' }).toISO(),
        endDate: DateTime.fromJSDate(endDate, { zone: 'utc' }).toISO(),
      });
      setChartData({
        labels: getLabels(data),
        datasets: getDataSets(data)
      })
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 403) setAuthError(e);
      else setError(e);
    }
  }

    React.useEffect(() => {
        getData();
    }, [startDate, endDate]);

    function getDataSets(data) {
        const billable = [];
        const nonBillable = [];
        const totalAllocation = [];
        for (const { billable_allocation, non_billable_allocation } of Object.values(data)) {
            billable.push(billable_allocation);
            nonBillable.push(non_billable_allocation);
            totalAllocation.push(billable_allocation + non_billable_allocation);
        }
        return [
            {
                label: 'Billable',
                data: billable,
                backgroundColor: colors.green,
                borderColor: colors.green
            },
            {
                label: "Non Billable",
                data: nonBillable,
                backgroundColor: colors.blue,
                borderColor: colors.blue
            },
            {
                label: "Total Allocation",
                data: totalAllocation,
                backgroundColor: colors.purple,
                borderColor: colors.purple
            }
        ];
    }
    const getLabels = (data) => {
        const labels = [];
        const sortedData = data.sort((a, b) => a.date > b.date ? 1 : -1);
        sortedData.forEach((dataPoint) => labels.push(dataPoint.date));
        return labels;
    }

    const getLineChartOptions = () => ({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          callbacks: {
            label: (ctx) => {
              return `${ctx.dataset.label}: ${
                ctx.dataset.data[ctx.dataIndex]
              }%`;
            },
          },
        },
        datalabels: {
          font: {
            size: 14,
          },
          align: "end",
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Date",
          },
          type: "time",
          adapters: {
            date: {
                zone: 'utc'
            },
          },
          time: {
            unit: AGGREGATION_TYPE,
            displayFormats: { year: 'MM' },
            tooltipFormat: 'MMM yyyy',
          },
          ticks: {
            source: 'ticks',
            autoSkip: false
          }
        },
        y: {
          title: {
            display: true,
            text: "Allocation %",
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 20,
          },
        },
      },
    });

    return (
        <Card className="h-100">
            <Card.Header>
                <div className="d-flex align-items-center">
                    <b>Provisional Allocation</b>
                    <div className="d-flex ms-auto align-items-center justify-content-center">
                        <div className="d-flex justify-content-center align-items-center me-4">
                            <span className="me-2">Start</span>
                            <Form.Control onChange={e => setStartDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center me-4">
                            <span className="me-2">End</span>
                            <Form.Control onChange={e => setEndDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')} />
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {error ? <p><b>Error:</b> {error.response.data.message}</p> : !chartData ? <Spinner className='mt-2' animation="border" /> : <Line data={chartData} options={getLineChartOptions()} />}
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProvisionalAllocationChart;
