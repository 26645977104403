import { DateTime } from 'luxon';
import React from 'react';
import { useState } from 'react';
import { Form, Button, Breadcrumb, Alert, Modal } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { addProject, getContacts, getCustomers } from '../../ApiConnection';
import { useAuthStore } from '../../stores/AuthStore';
import { ArrowClockwise } from 'react-bootstrap-icons';
import CreateContactModal from '../contact/CreateContactModal';
import { getHelmet } from '../../UtilFunctions';

const CreateProject = () => {
    const { token, tenant } = useAuthStore();
    const location = useLocation();
    const [name, setName] = React.useState('');
    const [startDate, setStartDate] = React.useState(DateTime.now().toJSDate());
    const [endDate, setEndDate] = React.useState(null);
    const [customer, setCustomer] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [internalResponsible, setInternalResponsible] = React.useState('');
    const [customerResponsible, setCustomerResponsible] = React.useState('');
    const [accountManager, setAccountManager] = useState('');
    const [customers, setCustomers] = React.useState([]);
    const [contacts, setContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = React.useState();

    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/projects/${uuid}`;
        navigate(path);
    }

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            const result = await addProject(token, tenant, name, startDate, endDate, customer, description, internalResponsible, customerResponsible, accountManager);
            routeChange(result.id);
        } catch (error) {
            setError(error.response.data)
        }
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Project Creation', 'Creation of project', true);
    }

    const fetchContacts = async () => setContacts(['', ...(await getContacts(token, tenant))]);

    const toggleModal = () => setShowModal(!showModal);

    React.useEffect(() => {
        async function getData() {
            try {
                await fetchContacts();
                const customers = await getCustomers(token, tenant);
                setCustomer(location?.state?.customer ? location.state.customer : customers[0].id);
                setCustomers(customers);
            } catch (e) {
                navigate(`/NotAuthenticated/${e.response.status}`, {
                    state: { prevLocation: location.pathname}
                });
                setError(e);
            }
        }
        getData()
    }, []);

    return (
        <div>
            <Helmet />
            <CreateContactModal show={showModal} toggle={toggleModal} fetchContacts={fetchContacts} />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/projects" }} >Projects</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Project</Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit} >
                <Form.Group className="mb-3">
                    <Form.Label>Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Customer <span className='text-danger'>*</span></Form.Label>
                    <Form.Select value={customer} onChange={e => setCustomer(e.target.value)}>
                        {customers.map(arrayData => {
                            return (
                                <option key={arrayData.id} value={arrayData.id}>{arrayData.name}</option>
                            )
                        }
                        )}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Start Date <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setStartDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <div className='d-flex align-items-center'>
                        <Form.Control className='w-75' onChange={e => setEndDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')} />
                        <a className='link-primary ms-3' role='button' onClick={(() => setEndDate(null))}>reset</a>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" onChange={e => setDescription(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Internal Responsible <span className='text-danger'>*</span></Form.Label>
                    <div>
                        <Form.Select className="w-75 d-inline" onChange={e => setInternalResponsible(e.target.value) } required>
                            {contacts.map(arrayData => <option key={arrayData.id} value={arrayData.id}>{arrayData.name} {arrayData.lastname}</option>)}
                        </Form.Select>
                        <ArrowClockwise className="ms-3 fs-4" style={{cursor: 'pointer'}} onClick={() => fetchContacts()}></ArrowClockwise>
                        <a className='link-primary ms-3' role='button' onClick={() => toggleModal()}>Create a new contact</a>
                    </div> 
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Customer Responsible</Form.Label>
                    <div>
                        <Form.Select className="w-75 d-inline" onChange={e => setCustomerResponsible(e.target.value) }>
                            {[...contacts, { id: '', name: '', lastname: ''}].map(arrayData => <option key={arrayData.id} value={arrayData.id}>{arrayData.name} {arrayData.lastname}</option>)}
                        </Form.Select>
                        <ArrowClockwise className="ms-3 fs-4" style={{cursor: 'pointer'}} onClick={() => fetchContacts()}></ArrowClockwise>
                        <a className='link-primary ms-3' role='button' onClick={() => toggleModal()}>Create a new contact</a>
                        <a className='link-primary ms-3' role='button' onClick={(() => setCustomerResponsible(''))}>reset</a>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Account Manager</Form.Label>
                    <Form.Control onChange={e => setAccountManager(e.target.value)}/>
                </Form.Group>
                {error &&
                    <Alert className={`mb-3 ${!error ? 'd-none': ''}`} variant="danger">
                        {error}
                    </Alert>
                }
                <Button variant="dark" type="submit">Add Project</Button>
            </form>
        </div>
    );
}

export default CreateProject;
