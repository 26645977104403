import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const EmployeeAssignmentTableCompact = ({ assignments, companyView, companyName }) => {

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/assignments/${id}`;
        navigate(path);
    }

    const handleEdit = (id, e) => {
        e.stopPropagation();
        navigate(`/assignments/edit/${id}`)
    }

    const getTableBody = (assignments, companyName) => {
        return assignments.map((arrayData, key) => {
            return (
                <tr key={key} className='text-center' onClick={() => routeChange(arrayData.id)} style={{ cursor: 'pointer' }}>
                    <td>{companyView ? companyName : arrayData.customer_name}</td>
                    <td>{arrayData.project_name}</td>
                    <td>{arrayData.employee_firstname} {arrayData.employee_lastname}</td>
                    <td>{formatDate(arrayData.start_date)}</td>
                    <td>{formatDate(arrayData.end_date)}</td>
                    <td>{Math.round(arrayData.allocation_percentage * 100)}%</td>
                    <td>{arrayData.billable ? 'Yes' : 'No'}</td>
                    <td className='d-flex align-items-center justify-content-center'><Button onClick={e => handleEdit(arrayData.id, e)} variant="dark">Edit</Button></td>
                </tr>
            )
        });
    }

    if(assignments.length > 0) {
    return (
        <div>
            <Table striped bordered hover className='mt-2'>
                <thead>
                    <tr className='text-center'>
                        <th>Customer</th>
                        <th>Project</th>
                        <th>Employee</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Allocation percentage</th>
                        <th>Billable</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {getTableBody(assignments, companyName)}
                </tbody>
            </Table>
        </div>
    );
    } else {
        return <p className="mt-2">No Assignments found</p>
    }
}

export default EmployeeAssignmentTableCompact;