import { useEffect, useState } from "react";
import { Breadcrumb, Button, Table, Form, Spinner, Toast } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { adminAddRoles, getAvailableRolesOfTenant, adminGetRolesOfUser } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { getHelmet } from "../../../UtilFunctions";

const CreateRoles = () => {
  const { token } = useAuthStore();
  const { id, username } = useParams();
  const [availableRoles, setAvailableRoles] = useState([]);
  const [existingRoles, setExistingRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function getData() {
      try {
        setExistingRoles((await adminGetRolesOfUser(token, id, username)).map(role => role.role_name));
        setAvailableRoles(await getAvailableRolesOfTenant(token, id));
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (error?.response.status === 401 || error?.response.data === 403) (
      navigate(`/NotAuthenticated/${error.response.status}`, {
        state: { prevLocation: location.pathname },
      })
    );
  }, [error]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (roles.length > 0) {
        await adminAddRoles(token, id, username, roles.filter(role => !existingRoles.includes(role)));
        navigate(`/manage/${id}/users/${username}/roles`);
      } else setShowToast(true);
    } catch (e) {
      setError(e);
    }
  };

  const handleSelect = (role) => setRoles([...new Set([...roles, role])]);

  const SelectedTable = () => (
    <Table striped bordered hover className="mt-2">
      <thead>
        <tr>
          <th>role</th>
          <th>remove</th>
        </tr>
      </thead>
      <tbody>
        {roles.map((role, key) => (
          <tr key={key}>
            <td>{role}</td>
            <td>
              <Button
                onClick={() =>
                  setRoles(roles.filter((selected) => selected !== role))
                }
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Roles Creation', 'Creation of roles for a user', true);
  }

  return (
    <div>
      <Helmet />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/manage/${id}/users` }}>
          Users
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `/mnage/${id}/users/${username}` }}
        >
          {username}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{
            to: `/manage/${id}/users/${username}/roles`,
          }}
        >
          Roles
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create Roles</Breadcrumb.Item>
      </Breadcrumb>
      {loading ? (
          <Spinner className="mt-2" animation="border" />
        ) : (
          <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
            <Form.Group className="mb-3 w-100">
              <Form.Label>Roles</Form.Label>
              <Form.Select onChange={e => handleSelect(e.target.value)} value=''>
                {[...availableRoles, ''].filter(availableRole => !roles.includes(availableRole) && !existingRoles.includes(availableRole)).map((availableRole, key) => <option key={key} value={availableRole}>{availableRole}</option>)}
              </Form.Select>
            </Form.Group>
            <SelectedTable></SelectedTable>
            <Button className="w-25" variant="dark" type="submit">
              Add roles
            </Button>
          </Form>
        )
      }
      <Toast bg="danger" autohide show={showToast} onClose={() => setShowToast(false)}>
        <Toast.Body>
          You must select at least 1 role.
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default CreateRoles;
