import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import { deleteEmployeeCost } from "../../ApiConnection";
import { useAuthStore } from "../../stores/AuthStore";
import { formatDate } from "../../UtilFunctions";


const EmployeeCostTable = ({ costs, refreshEmployeeCosts, current }) => {
    const { id } = useParams();
    const { token, tenant } = useAuthStore();
    const [selectedCostId, setSelectedCostId] = useState('');
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleShow = (id) => {
        setSelectedCostId(id);
        setShow(true);
        handleClose();
    };
    const handleClose = () => setShow(false);
    const handleDelete = async (id) => {
        await deleteEmployeeCost(token, tenant, id);
        refreshEmployeeCosts();
    }

    return (
        <div className='w-100'>
            <Table bordered className='mt-2 '>
                <thead>
                    <tr>
                        <th>Employee</th>
                        <th>From</th>
                        <th>Amount</th>
                        <th>Edit/Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {costs.map(cost => {
                        return (
                            <tr key={cost.id} className={cost.id === current ? 'current-td' : ''}>
                                <td>{cost.name} {cost.lastname}</td>
                                <td>{formatDate(cost.from_date)}</td>
                                <td>€{cost.amount}</td>
                                <td className="d-flex justify-content-center align-items-center">
                                    <Button className="btn me-2" onClick={() => navigate(`/employees/${id}/cost/edit/${cost.id}`)}>Edit</Button>
                                    <Button className="btn btn-danger ms-2" onClick={() => handleShow(cost.id)}>Remove</Button>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee cost</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Employee cost!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(selectedCostId) }}>
                        Delete employee cost
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmployeeCostTable;