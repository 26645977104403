import { Table } from "react-bootstrap";

const shownAttributes = ['sub', 'name', 'email'];

const UserAttributeTable = ({ attributes }) => (
  <Table striped bordered hover className="mt-2">
    <thead>
      <tr>
        <th>Name</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      {attributes.filter(attribute => shownAttributes.includes(attribute.Name)).map((attribute, key) => (
        <tr key={key}>
          <td>{attribute.Name}</td>
          <td>{attribute.Value}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default UserAttributeTable;
