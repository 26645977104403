import { Dropdown } from "react-bootstrap";
import { CHART_AGGREGATION_TYPES } from '../../../constants';

const AggregationTypeDropdown = ({ aggregationType, setAggregationType }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="dark">
                {aggregationType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setAggregationType(CHART_AGGREGATION_TYPES.day)}>day</Dropdown.Item>
                <Dropdown.Item onClick={() => setAggregationType(CHART_AGGREGATION_TYPES.month)}>month</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AggregationTypeDropdown;