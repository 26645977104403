import React from "react";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFill } from "react-bootstrap-icons";


const AvailableEmployeeTable = ({ employees, searchNextWeek }) => {
    const navigate = useNavigate();

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            The minimum weekly allocation during the given period.
        </Tooltip>
    );

    return employees.length > 0 ? (
        <div className='w-100'>
            <Table striped bordered hover className='mt-2 text-center'>
                <thead>
                    <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Free Allocation <OverlayTrigger placement="bottom"
                            overlay={renderTooltip}><ExclamationCircleFill className="fs-7" /></OverlayTrigger></th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map(arrayData => {
                        return (
                            <tr key={arrayData.id} onClick={() => navigate(`/employees/${arrayData.id}`)} style={{ cursor: 'pointer' }}>
                                <td>{arrayData.first_name}</td>
                                <td>{arrayData.last_name}</td>
                                <td>{arrayData.allocation}%</td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>
        </div>
    ) : (
        <div className="mt-5">
            <h3>No results found, check <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => searchNextWeek()}>next week</span></h3>
        </div>
    );
}

export default AvailableEmployeeTable;