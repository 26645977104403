import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import { deleteEmployeeAssignmentIncome } from "../../ApiConnection";
import { useAuthStore } from "../../stores/AuthStore";
import { formatDate } from "../../UtilFunctions";


const EmployeeAssignmentIncomeTable = ({ incomes, refreshEmployeeAssignmentIncomes, current }) => {
    const { id } = useParams();
    const { token, tenant } = useAuthStore();
    const [selectedIncomeId, setSelectedIncomeId] = useState('');
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleShow = (id) => {
        setSelectedIncomeId(id);
        setShow(true);
        handleClose();
    };
    const handleClose = () => setShow(false);
    const handleDelete = async (id) => {
        await deleteEmployeeAssignmentIncome(token, tenant, id);
        refreshEmployeeAssignmentIncomes();
    }

    return (
        <div className='w-100'>
            <Table bordered className='mt-2'>
                <thead>
                    <tr>
                        <th>From</th>
                        <th>Amount</th>
                        <th>Edit/Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {incomes.map((income, key) => {
                        return (
                            <tr key={key} className={income.id === current ? 'current-td' : ''}>
                                <td>{formatDate(income.from_date)}</td>
                                <td>€{income.amount}</td>
                                <td className="d-flex justify-content-center align-items-center">
                                    <Button className="btn me-2" onClick={() => navigate(`/assignments/${id}/income/edit/${income.id}`)}>Edit</Button>
                                    <Button className="btn btn-danger ms-2" onClick={() => handleShow(income.id)}>Remove</Button>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee assignment income</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Employee assignment income!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(selectedIncomeId) }}>
                        Delete employee asignment income
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmployeeAssignmentIncomeTable;