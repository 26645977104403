import { DateTime } from "luxon";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { JwtExpiredError } from 'aws-jwt-verify/error';
import { Helmet } from 'react-helmet-async';

const verifier = CognitoJwtVerifier.create({
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    tokenUse: "access",
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

export const checkJwtExpired = async (token) => {
    try {
        await verifier.verify(token);
        return false;
    } catch (error) {
        if (error instanceof JwtExpiredError) return true;
        else return false;
    }
}

export const checkToken = async (token) => {
    try {
        await verifier.verify(token);
        return token;
    } catch {
        return '';
    }
}

export const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
}

export const calculateTimeBetween = (dateString) => {
    const date1 = DateTime.fromISO(dateString)
    const date2 = DateTime.now();

    const diff = date2.diff(date1, ["years", "months", "days", "hours", "minutes"])

    if (diff.years > 0) return `${diff.years} year(s) ago`;
    else if (diff.months > 0) return `${diff.months} month(s) ago`;
    else if (diff.days > 0) return `${diff.days} day(s) ago`;
    else if (diff.hours > 0) return `${diff.hours} hour(s) ago`;
    else if (diff.minutes > 0) {
        if (diff.minutes < 1) return 'less than 1 min ago';
        return `${Math.trunc(diff.minutes)} min(s) ago`
    }
}

export const formatHourDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear() + "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("00" + date.getDate()).slice(-2) + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);
}

export const formatdateformated = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear() + "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("00" + date.getDate()).slice(-2)
}

// no ternary because of multiple criteria
export const dateSort = (a, b) => {
    // first on start date
    if (DateTime.fromISO(a.start_date).startOf('day') > DateTime.fromISO(b.start_date).startOf('day')) return 1;
    if (DateTime.fromISO(a.start_date).startOf('day') < DateTime.fromISO(b.start_date).startOf('day')) return -1;

    // then on end date if start date same day (most precise displayed is day)
    if (DateTime.fromISO(a.end_date).startOf('day') > DateTime.fromISO(b.end_date).startOf('day')) return 1;
    if (DateTime.fromISO(a.end_date).startOf('day') < DateTime.fromISO(b.end_date).startOf('day')) return -1;
};

export const simpleDateSort = (a, b) => DateTime.fromISO(a.date) > DateTime.fromISO(b.date) ? 1 : -1;

export const checkIfCookieLastTenantInCurrentTenants = (lastTenant, tenants) => tenants.find(tenant => tenant.id === lastTenant)?.id;

export const getHelmet = (title, description, robots = false) => (
    <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        {robots && <meta name="robots" content="noindex" />}
    </Helmet>
);

export const colors = {
    yellow: "#fcba03",
    red: "#f54263",
    green: "#75f542",
    blue: "#42c2f5",
    purple: "#600475",
    black: "#0a0a0a"
};