import CurrentAllocationChart from './charts/CurrentAllocationChart';
import LowestAllocationTable from './charts/LowestAllocationTable';
import ProvisionalAllocationChart from './charts/ProvisionalAllocationChart';
import LatestNegativeTouchPointTable from './charts/LatestNegativeTouchPointTable';
import StaleTouchPointTable from './charts/StaleTouchPointTable';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getHelmet } from '../UtilFunctions';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CostIncomeBarChart, IncomeBarChart } from './charts';
import { useAuthStore } from '../stores/AuthStore';
import { observer } from 'mobx-react';
import { CHART_AGGREGATION_TYPES } from '../constants';

const Dashboard = () => {
    const { roles } = useAuthStore();
    const [authError, setAuthError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (authError) {
            navigate(`/NotAuthenticated/${authError?.response?.status || 500}`, {
                state: { prevLocation: location.pathname }
            });
        }
    }, [authError]);

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Dashboard', 'Dashboard with multiple charts regarding allocation and customer touchpoints', true);
    }

    return (
        <div>
            <Helmet />
            <Container className="text-center mt-5">
                <Row>
                    <Col xd={2}>                    
                        <CurrentAllocationChart setAuthError={setAuthError} />
                    </Col>
                    <Col xs={8} className='no-mobile'>
                        <ProvisionalAllocationChart setAuthError={setAuthError} />
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <LowestAllocationTable setAuthError={setAuthError} />
                    </Col>
                    <Col>
                        <LatestNegativeTouchPointTable setAuthError={setAuthError} />                      
                    </Col>
                    <Col>
                        <StaleTouchPointTable setAuthError={setAuthError} />
                    </Col>
                </Row>
                {roles.includes('Financial') ?
                    <Row className='mt-4'>
                        <Col className='no-mobile'>
                            <CostIncomeBarChart setAuthError={setAuthError} full aggregationTypes={[CHART_AGGREGATION_TYPES.month]} />
                        </Col>
                    </Row>
                : roles.includes('Sales') ?
                    <Row className='mt-4'>
                        <Col className='no-mobile'>
                            <IncomeBarChart setAuthError={setAuthError} full />
                        </Col>
                    </Row>
                : null
                }
            </Container>
        </div>
    );
}

export default observer(Dashboard);

