import { useEffect, useState } from "react";
import { Breadcrumb, Button, Table, Form, Spinner, Toast, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { adminAddRoles, getAvailableRolesOfTenant, adminGetRolesOfUser } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { getHelmet } from "../../../UtilFunctions";

const CreateRolesAnyUser = () => {
  const { token } = useAuthStore();
  const { id } = useParams();
  const [availableRoles, setAvailableRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [username, setUsername] = useState('');
  const [cronosUser, setCronosUser] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function getData() {
      try {
        setAvailableRoles(await getAvailableRolesOfTenant(token, id));
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (error?.response.status === 401 || error?.response.data === 403) (
      navigate(`/NotAuthenticated/${error.response.status}`, {
        state: { prevLocation: location.pathname },
      })
    );
  }, [error]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (roles.length > 0) {
        await adminAddRoles(token, id, cronosUser ? `cronos_${username}` : username, roles);
        navigate(`/manage/${id}/users/${username}/roles`);
      } else setShowToast(true);
    } catch (e) {
      setError(e);
    }
  };

  const handleSelect = (role) => setRoles([...new Set([...roles, role])]);

  const SelectedTable = () => (
    <Table striped bordered hover className="mt-2">
      <thead>
        <tr>
          <th>role</th>
          <th>remove</th>
        </tr>
      </thead>
      <tbody>
        {roles.map((role, key) => (
          <tr key={key}>
            <td>{role}</td>
            <td>
              <Button
                onClick={() =>
                  setRoles(roles.filter((selected) => selected !== role))
                }
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Roles Creation', 'Creation of roles for a user', true);
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        The username will automatically be prepended with 'cronons_'. This is necessary for Cronos users.
    </Tooltip>
  );

  return (
    <div>
      <Helmet />
      <Breadcrumb>
        <Breadcrumb.Item active>Create Roles</Breadcrumb.Item>
      </Breadcrumb>
      {loading ? (
          <Spinner className="mt-2" animation="border" />
        ) : (
          <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
            <Form.Group className="mb-3">
                <Form.Check.Label>
                    Cronos <OverlayTrigger placement="bottom" overlay={renderTooltip}><ExclamationCircleFill className="fs-7" /></OverlayTrigger>
                </Form.Check.Label>
                <Form.Check className="d-inline ms-2" type="switch" onChange={e => setCronosUser(e.target.checked)} checked={cronosUser} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Username <span className='text-danger'>*</span></Form.Label>
                <Form.Control onChange={e => setUsername(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={username} required />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Roles</Form.Label>
              <Form.Select onChange={e => handleSelect(e.target.value)} value=''>
                {[...availableRoles, ''].filter(availableRole => !roles.includes(availableRole)).map((availableRole, key) => <option key={key} value={availableRole}>{availableRole}</option>)}
              </Form.Select>
            </Form.Group>
            <SelectedTable></SelectedTable>
            <Button className="w-25" variant="dark" type="submit">
              Add roles
            </Button>
          </Form>
        )
      }
      <Toast bg="danger" autohide show={showToast} onClose={() => setShowToast(false)}>
        <Toast.Body>
          You must select at least 1 role.
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default CreateRolesAnyUser;
