import React, { useState } from 'react';
import { Form, Button, Breadcrumb, Alert } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getEmployeeAssignments, getCustomerTouchPoint, updateCustomerTouchPoint, getContacts } from "../../ApiConnection"
import { useAuthStore } from '../../stores/AuthStore'
import Select from 'react-select'
import SentimentComponent from './SentimentComponent';
import { formatDate, formatdateformated, getHelmet } from "../../UtilFunctions";
import { ArrowClockwise } from 'react-bootstrap-icons';
import CreateContactModal from '../contact/CreateContactModal';

const EditTouchPoint = () => {
    const { id } = useParams();
    const { token, tenant } = useAuthStore();
    const [touchPoint, setTouchPoint] = useState('');
    const [sentiment, setSentiment] = React.useState('');
    const [contact, setContact] = React.useState('');
    const [internContact, setInternContact] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [date, setDate] = React.useState('');
    const [project, setProject] = React.useState('');
    const [active, setActive] = React.useState(true);
    const [selectedOptions, setSelectedOptions] = React.useState("");
    const [employeeassignments, setEmployeeAssignments] = React.useState([{ value: "1", label: "test" }, { value: "3", label: "test3" }, { value: "2", label: "test2" }, , { value: "4", label: "test4" }, , { value: "5", label: "test5" }]);
    const [contacts, setContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = React.useState();

    function handleSelect(data) {
        setSelectedOptions(data);
    }

    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/touchpoints/${uuid}`;
        navigate(path);
    }

    const filterAssignments = () => {
        return employeeassignments.filter(assignment => assignment.value.project_id === project);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Touch Point Edit', 'Make edits to customer touch point', true);
    }

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            await updateCustomerTouchPoint(token, tenant, id, sentiment, date, comment, contact, internContact, project, selectedOptions, active);
            routeChange(id);
        } catch (e) {
            setError(e);
        }
    }

    const fetchContacts = async () => setContacts(await getContacts(token, tenant));

    const toggleModal = () => setShowModal(!showModal);

    React.useEffect(() => {
        async function getData() {
            try {
                await fetchContacts();
                const touchPoint = await getCustomerTouchPoint(token, tenant, id);
                setSentiment(touchPoint.sentiment);
                setDate(touchPoint.date);
                setComment(touchPoint.comment);
                setContact(touchPoint.contact.id);
                setInternContact(touchPoint.intern_contact.id);
                setProject(touchPoint.project);
                setActive(touchPoint.active === 1 ? true : false);
                const options = [];
                touchPoint.assignments.forEach(element => {
                    options.push({ value: element, label: `${element.employee_name} ${element.employee_lastname} (${formatDate(element.start_date)}-${formatDate(element.end_date)})` })
                });
                setSelectedOptions(options);
                const assign = await getEmployeeAssignments(token, tenant);
                const array = [];
                assign.forEach(element => {
                    array.push({ value: element, label: `${element.employee_name} ${element.employee_lastname} (${formatDate(element.start_date)}-${formatDate(element.end_date)})` })
                });
                setEmployeeAssignments(array);
                setTouchPoint(touchPoint);
            } catch (e) {
                setError(e);
            }
        }
        getData()
    }, []);

    return (
        <div>
            <Helmet />
            <CreateContactModal show={showModal} toggle={toggleModal} fetchContacts={fetchContacts} />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/touchpoints" }} >Touchpoints</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Touchpoint</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Touch Point</h5>
            {(touchPoint &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className="mb-3">
                        <Form.Group>
                            <Form.Label>Customer: {filterAssignments()[0].value.customer_name}</Form.Label>
                        </Form.Group>
                        <Form.Label>Sentiment <span className='text-danger'>*</span></Form.Label>
                        <Form.Check
                            label={<div><SentimentComponent className="d-inline" sentiment={"HAPPY"} /> <p className="d-inline">Happy</p></div>}
                            name="sentiment"
                            type='radio'
                            checked={sentiment === "HAPPY"}
                            onChange={e => setSentiment("HAPPY")}
                            id={`inline-radio-1`}
                            required
                        />
                        <Form.Check
                            label={<div><SentimentComponent className="d-inline" sentiment={"NEUTRAL"} /> <p className="d-inline">Neutral</p></div>}
                            name="sentiment"
                            type='radio'
                            checked={sentiment === "NEUTRAL"}
                            onChange={e => setSentiment("NEUTRAL")}
                            id={`inline-radio-2`}
                            required
                        />
                        <Form.Check
                            name="sentiment"
                            label={<div><SentimentComponent className="d-inline" sentiment={"UNHAPPY"} /> <p className="d-inline">Unhappy</p></div>}
                            type={'radio'}
                            checked={sentiment === "UNHAPPY"}
                            onChange={e => setSentiment("UNHAPPY")}
                            id={`inline-radio-3`}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setDate(new Date(e.target.value))} type="date" value={formatdateformated(date)} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control onChange={e => setComment(e.target.value)} value={comment} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Contact <span className='text-danger'>*</span></Form.Label>
                        <div>
                            <Form.Select className="w-75 d-inline" onChange={e => setContact(e.target.value)} value={contact} required>
                                {contacts.map(arrayData => <option key={arrayData.id} value={arrayData.id}>{arrayData.name} {arrayData.lastname}</option>)}
                            </Form.Select>
                            <ArrowClockwise className="ms-3 fs-4" style={{ cursor: 'pointer' }} onClick={() => fetchContacts()}></ArrowClockwise>
                            <a className='link-primary ms-3' role='button' onClick={() => toggleModal()}>Create a new contact</a>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Intern contact <span className='text-danger'>*</span></Form.Label>
                        <div>
                            <Form.Select className="w-75 d-inline" onChange={e => setInternContact(e.target.value)} value={internContact} required>
                                {contacts.map(arrayData => <option key={arrayData.id} value={arrayData.id}>{arrayData.name} {arrayData.lastname}</option>)}
                            </Form.Select>
                            <ArrowClockwise className="ms-3 fs-4" style={{ cursor: 'pointer' }} onClick={() => fetchContacts()}></ArrowClockwise>
                            <a className='link-primary ms-3' role='button' onClick={() => toggleModal()}>Create a new contact</a>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Assignment</Form.Label>
                        <Select
                            isMulti
                            value={selectedOptions}
                            onChange={handleSelect}
                            options={filterAssignments()}
                        />
                    </Form.Group>
                    {(error &&
                        <Alert className='mb-3' variant="danger">
                            {error}
                        </Alert>)}
                    <Button variant="dark" type="submit">Edit Touchpoint</Button>
                </form>
            )}
        </div>
    );
}

export default EditTouchPoint;
