import { Bar, Line } from 'react-chartjs-2';
import { Chart, Legend, Tooltip, TimeScale, LinearScale, PointElement, CategoryScale, Title, BarElement } from 'chart.js';
import { DateTime } from 'luxon';
import { useAuthStore } from '../../stores/AuthStore';
import {Button, Card, Form, Spinner} from "react-bootstrap";
import AggregationTypeDropdown from "./dropdowns/AggregationTypeDropdown";
import { useEffect, useState } from 'react';
import { getCostIncomeFor, getCostIncomeFull } from '../../ApiConnection';
import { useParams } from 'react-router-dom';
import { colors } from '../../UtilFunctions'
import { CHART_AGGREGATION_TYPES } from '../../constants';
import { round } from '../../utils';

Chart.register(BarElement, Legend, Tooltip, TimeScale, LinearScale, PointElement, CategoryScale, Title);


const CostIncomeBarChart = ({ setAuthError, full, on, refresh, aggregationTypes = Object.values(CHART_AGGREGATION_TYPES), aggregationType = CHART_AGGREGATION_TYPES.month }) => {
    const defaultDuration = aggregationType === CHART_AGGREGATION_TYPES.day ? { week: 1 } : { month: 2 }

    let { id } = useParams();
    const [chartData, setChartData] = useState(null);
    const [aggregationChartType, setAggregationChartType] = useState(aggregationType);
    const [from, setFrom] = useState(DateTime.utc().startOf('month').minus(defaultDuration).toJSDate());
    const [to, setTo] = useState(DateTime.utc().startOf('month').plus(defaultDuration).toJSDate());
    const [error, setError] = useState('');
    const { token, tenant } = useAuthStore();

    const getData = async () => {
        try {
            setError('');
            setChartData(null);
            const params = {
                aggregationType: aggregationChartType,
                from: DateTime.fromJSDate(from, { zone: 'utc' }).toISO(),
                to: DateTime.fromJSDate(to, { zone: 'utc' }).toISO(),
            };
            if (!full) params.on = on;

            const data = full ? await getCostIncomeFull(token, tenant, params) : await getCostIncomeFor(token, tenant, id, params);
            setChartData({
                labels: getLabels(data),
                datasets: getDataSets(data),
            });
        } catch (e) {
            if (e?.response?.status === 401 || e?.response?.status === 403) setAuthError(e);
            else setError(e);
        }
    };

    useEffect(() => {
        getData();
    }, [token, tenant, aggregationChartType, from, to]);

    const getDataSets = (data) => {
        const dataSets = data.reduce((acc, cur) => ({ cost: [...acc.cost, round(cur.cost)], income: [...acc.income, round(cur.income)] }), { cost: [], income: [] });

        return [
            {
                label: 'Income',
                data: dataSets.income,
                backgroundColor: colors.green,
                borderColor: colors.green
            },
            {
                label: 'Cost',
                data: dataSets.cost,
                backgroundColor: colors.red,
                borderColor: colors.red
            }
        ];
    };

    const displayFormatMapper = {
        day: { day: 'MMM dd' },
        month: { year: 'MM' },
    };

    const tooltipFormatMapper = {
        day: 'dd MMM yyyy',
        month: 'MMM yyyy',
    };

    const getLabels = (data) => data.sort((a, b) => a.date > b.date ? 1 : -1).map(dataPoint => dataPoint.date);
    const getBarChartOptions = () => ({
        responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: (ctx) => {
              return `${ctx.dataset.label}: €${
                ctx.dataset.data[ctx.dataIndex]
              }`;
            },
          },
        },
        datalabels: {
          display: aggregationType === CHART_AGGREGATION_TYPES.month, //due to the huge amount of data, hide when aggregation type is daily
          font: {
            size: 14,
          },
          align: "end",
          formatter: (value) => `€${value}`,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Date",
          },
          type: "time",
          adapters: {
            date: {
                zone: 'utc'
            },
          },
          time: {
            unit: aggregationChartType,
            displayFormats: displayFormatMapper[aggregationChartType],
            tooltipFormat: tooltipFormatMapper[aggregationChartType],
          },
          ticks: {
            source: 'ticks',
            autoSkip: false
          }
        },
        y: {
          title: {
            display: true,
            text: "Amount (€)",
          },
        },
      },
    });

    return (
        <Card className="h-100">
            <Card.Header>
                <div className="d-flex align-items-center">
                    <b>Income - Cost</b>
                    {refresh && <Button className='ms-4' variant='dark' onClick={() => getData()}>Refresh</Button>}
                    <div className="d-flex ms-auto align-items-center justify-content-center">
                        <div className="d-flex justify-content-center align-items-center me-4">
                            <span className="me-2">From</span>
                            <Form.Control onChange={e => setFrom(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(from).toFormat('yyyy-MM-dd')} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center me-4">
                            <span className="me-2">To</span>
                            <Form.Control onChange={e => setTo(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(to).toFormat('yyyy-MM-dd')} />
                        </div>
                      { aggregationTypes.length > 1 && <AggregationTypeDropdown aggregationType={aggregationChartType} setAggregationType={setAggregationChartType} /> }
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {error ? <p><b>Error:</b> {error.response.data}</p> : !chartData ? <Spinner className='mt-2' animation="border" /> : <Bar data={chartData} options={getBarChartOptions()} />}
                </div>
            </Card.Body>
        </Card>
    );
};

export default CostIncomeBarChart;