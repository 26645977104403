import { useParams, Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Col, Row, Form, Alert, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { updateProject, getprojectDetail, getCustomers, getContacts } from '../../ApiConnection'
import { formatdateformated, formatDate, getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore';
import { DateTime } from 'luxon';
import { ArrowClockwise } from 'react-bootstrap-icons';
import CreateContactModal from '../contact/CreateContactModal';

const EditProject = () => {
    const { token, tenant } = useAuthStore();
    const { id } = useParams();
    const [project, setProject] = React.useState("");
    const [name, setName] = React.useState('');
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [customer, setCustomer] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [internalResponsible, setInternalResponsible] = React.useState('');
    const [customerResponsible, setCustomerResponsible] = React.useState('');
    const [accountManager, setAccountManager] = useState('');
    const [active, setActive] = React.useState(true);
    const [customers, setCustomers] = React.useState([]);
    const [contacts, setContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = React.useState('');
    

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/projects/${id}`;
        navigate(path);
    }

    const fetchContacts = async () => setContacts(await getContacts(token, tenant));

    const toggleModal = () => setShowModal(!showModal);

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Project Edit', 'Make edits to project', true);
    }

    React.useEffect(() => {
        async function getData() {
            await fetchContacts();
            const customers = await getCustomers(token, tenant);
            setCustomers(customers);
            const project = await getprojectDetail(token, tenant, id);
            setName(project.name);
            setStartDate(new Date(project.start_date));
            setEndDate(new Date(project.end_date));
            setCustomer(project.customer);
            setDescription(project.description);
            setInternalResponsible(project.internal_responsible.id);
            setCustomerResponsible(project.customer_responsible.id);
            setAccountManager(project.account_manager);
            setActive(project.active === 1 ? true : false);
            setProject(project);
        }
        getData()
    }, []);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateProject(token, tenant, id, name, startDate, endDate, customer, description, internalResponsible, customerResponsible, accountManager, active);
            routeChange(id);
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet />
            <CreateContactModal show={showModal} toggle={toggleModal} fetchContacts={fetchContacts} />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/projects" }} >Projects</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Project</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Project</h5>
            {(project &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className="mb-3">
                        <Form.Label>Name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Customer <span className='text-danger'>*</span></Form.Label>
                        <Form.Select onChange={e => setCustomer(e.target.value)} value={customer} required>
                            {customers.map(arrayData => {
                                return (
                                    <option key={arrayData.id} value={arrayData.id}>{arrayData.name}</option>
                                )
                            }
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Date <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setStartDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>End Date</Form.Label>
                        <div className='d-flex align-items-center'>
                            <Form.Control className='w-75' onChange={e => setEndDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')} />
                            <a className='link-primary ms-3' role='button' onClick={(() => setEndDate(null))}>reset</a>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" onChange={e => setDescription(e.target.value)} value={description} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Internal Responsible <span className='text-danger'>*</span></Form.Label>
                        <div>
                            <Form.Select className="w-75 d-inline" onChange={e => setInternalResponsible(e.target.value) } value={internalResponsible} required>
                                {contacts.map(arrayData => <option key={arrayData.id} value={arrayData.id}>{arrayData.name} {arrayData.lastname}</option>)}
                            </Form.Select>
                            <ArrowClockwise className="ms-3 fs-4" style={{cursor: 'pointer'}} onClick={() => fetchContacts()}></ArrowClockwise>
                            <a className='link-primary ms-3' role='button' onClick={() => toggleModal()}>Create a new contact</a>
                        </div> 
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Customer Responsible</Form.Label>
                        <div>
                            <Form.Select className="w-75 d-inline" onChange={e => setCustomerResponsible(e.target.value) } value={customerResponsible}>
                                {[...contacts, { id: '', name: '', lastname: ''}].map(arrayData => <option key={arrayData.id} value={arrayData.id}>{arrayData.name} {arrayData.lastname}</option>)}
                            </Form.Select>
                            <ArrowClockwise className="ms-3 fs-4" style={{cursor: 'pointer'}} onClick={() => fetchContacts()}></ArrowClockwise>
                            <a className='link-primary ms-3' role='button' onClick={() => toggleModal()}>Create a new contact</a>
                            <a className='link-primary ms-3' role='button' onClick={(() => setCustomerResponsible(''))}>reset</a>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Account Manager</Form.Label>
                        <Form.Control onChange={e => setAccountManager(e.target.value)} value={accountManager}/>
                    </Form.Group>
                    {error &&
                        <Alert className={`mb-3 ${!error ? 'd-none': ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit Project</Button>
                </form>
            )}
        </div>
    );
}

export default EditProject;
