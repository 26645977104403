import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../UtilFunctions";

const TenantTable = ({ tenants, showRoles, username }) => {
  const navigate = useNavigate();

  return (
    <Table striped bordered hover className="mt-2">
      <thead className="text-center">
        <tr>
          <th>Name</th>
          <th>Created</th>
          {showRoles && (
            <>
              <th>Roles</th>
              <th>Edit roles</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {tenants.map((tenant, key) => (
          <tr
            key={key}
            onClick={() => navigate(`/admin-panel/tenants/${tenant.id}`)}
            style={{ cursor: "pointer" }}
          >
            <td>{tenant.name}</td>
            <td>{formatDate(tenant.created_at)}</td>
            {showRoles &&
              <>
                <td>{tenant.roles.toString()}</td>
                <td className="text-center">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/admin-panel/tenants/${tenant.id}/users/${username}/roles`
                      );
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TenantTable;
