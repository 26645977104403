import React, { useEffect, useState } from "react";
import { Button, ToggleButton, Container, Spinner, Breadcrumb } from "react-bootstrap";
import EmployeeTable from "./EmployeeTable";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom"
import { getEmployees } from "../../ApiConnection"
import { useAuthStore } from "../../stores/AuthStore";
import SearchBar from "../SearchBar";
import { getHelmet } from "../../UtilFunctions";

const Employees = () => {
    const { token, tenant } = useAuthStore();
    const [employees, setEmployees] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [active, setActive] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    const routeChange = () => {
        const path = `/employees/create`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Table', 'Listing of all employees', true);
    }

    const filterEmployees = (employee) => {
        if(active) {
            return (employee.name.toLowerCase().includes(searchValue) || employee.lastname.toLowerCase().includes(searchValue)) && employee.active === +active;
        }
        return employee.name.toLowerCase().includes(searchValue) || employee.lastname.toLowerCase().includes(searchValue);
    }


    useEffect(() => {
        async function getData() {
            try {
                setEmployees(await getEmployees(token,tenant));
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
        getData()
    }, []);

    useEffect(() => {
        if (error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`, {
                state: { prevLocation: location.pathname }
            });
        }
    }, [error]);

    function Table() {
        return (
            <div className="d-flex justify-content-center">
                {loading ? <Spinner className='mt-2' animation="border" /> : <EmployeeTable employees={employees.filter(filterEmployees).sort((a, b) => a.name > b.name ? 1 : -1)} />}
            </div>
        )
    }

    return (
        <Container>
            <Helmet />
            <div className="d-flex flex-column">
                <Breadcrumb className="mt-2">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Employees</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex justify-content-center align-items-center">
                    <SearchBar className="w-75" setSearchValue={setSearchValue} searchingOn={'name, lastname'}></SearchBar>
                    <ToggleButton
                        className="w-10 ms-2"
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-dark"
                        checked={active}
                        value="1"
                        onChange={(e) => setActive(e.currentTarget.checked)}>
                        Active
                    </ToggleButton>
                    <Button className="w-25 ms-2" onClick={routeChange} variant="dark">Add Employee</Button>
                </div>
            </div>
            <Table></Table>
        </Container>
    );
}

export default Employees;
